import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import locales from "../../../constants";

const Niegolewskich = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      seo={{
        title: "Niegolewskich 14 - Marketing inwestycji deweloperskiej",
        href: slug,
        lang: "en",
        featuredImage: require("../../../assets/img/portfolio/niegolewskich_og.jpg"),
      }}
      header={{
        background:
          "linear-gradient( 225deg, #814B18, #9E6B3A, #BC8D5D, #D9AF81, #F7D3A8 )",
        icons: "#3F230B",
        navClass: "niegolewskich",
        ogImage: require("../../../assets/img/portfolio/niegolewskich_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/niegolewskich14/",
      }}
    >
      <PortfolioHeader name="niegolewskich" height="135" />
      <section className="container-fluid niegolewskich_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Niegolewskich 14</h1>
              <ul>
                <li>Website</li>
                <li>Online Campaign</li>
              </ul>
            </div>
          </div>

          <div className="col-md-6">
            <div className="inner">
              <p>
                Our business partner came to us with an inquiry about the
                real-estate investment project. We joined the project at the
                stage in which the website and the campaign directed to the
                website did not achieve the Client's business goals.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="niegolewskich_section_scroll">
        <div className="scroll_wrapper scroll_wrapper--new">
          <span />
          <div className="img_wrapper">
            <img
              src={require("../../../assets/img/portfolio/niegolewskich_entire_page.jpg")}
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="niegolewskich_section_3">
        <div className="row no-gutters">
          <div className="col-md-6 offset-md-6">
            <h3>Website</h3>
            <p>
              The website created by another agency was as follows{" "}
              <a
                href="https://niegolewskich14.pl/"
                target="_blank"
                rel="noreferrer noindex nofollow"
              >
                niegolewskich14.pl
              </a>
              <br />
              Our task was to show exclusive and prestigious apartments in
              Poznań. The historic tenement house was created in the belle
              époque style, so we wanted to create a project that would reflect
              its atmosphere.
              <br />
              The website we created is available at{" "}
              <a
                href="https://lp.niegolewskich14.pl/"
                target="_blank"
                rel="noreferrer noindex nofollow"
              >
                lp.niegolewskich14.pl
              </a>
              .
            </p>
          </div>
        </div>
      </section>

      {/* <section className="container-fluid niegolewskich_section_4">
        <div className="gallery-row">
          <img
            src={require("../../assets/img/portfolio/niegolewskich-img-1.jpg")}
            alt=""
            className="img-fluid gallery-row__image image image--1"
          />
          <img
            src={require("../../assets/img/portfolio/niegolewskich-img-2.jpg")}
            alt=""
            className="img-fluid gallery-row__image image image--2"
          />
          <img
            src={require("../../assets/img/portfolio/niegolewskich-img-3.jpg")}
            alt=""
            className="img-fluid gallery-row__image image image--3"
          />
        </div>
      </section> */}

      <section className="container-fluid niegolewskich_section_5">
        <div className="row">
          <div className="col-md-12">
            <h3>Our project</h3>
            <img
              src={require("../../../assets/img/portfolio/niegolewskich-img-4.jpg")}
              alt="Nowa strona Niegolewskich14"
              className="img-fluid"
            />
          </div>
          <div className="col-md-9 offset-md-2">
            <img
              src={require("../../../assets/img/portfolio/niegolewskich-img-5.jpg")}
              alt="Kalkulator mieszkań"
              className="img-fluid image--2"
            />
          </div>
        </div>
      </section>

      <section className="container-fluid niegolewskich_section_6">
        <div className="row">
          <div className="col-md-10">
            <img
              src={require("../../../assets/img/portfolio/niegolewskich-img-6.jpg")}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-12">
            <img
              src={require("../../../assets/img/portfolio/niegolewskich-img-7.jpg")}
              alt=""
              className="img-fluid image--2"
            />
          </div>
        </div>
      </section>

      <section className="container-fluid niegolewskich_section_7">
        <div className="row align-items-center">
          <div className="col-md-5">
            <h3>Online campaign</h3>
            <p>
              In addition to the development of the website, we were responsible
              for conducting a promotional campaign aimed at attracting
              potential investors. During four months of our mutual cooperation
              with the sales office, we supported our Client by generating
              valuable leads.
            </p>
          </div>
          <div className="col-md-7">
            <img
              src={require("../../../assets/img/portfolio/niegolewskich-img-8.jpg")}
              alt="Przykład banerów"
              className="img-fluid"
            />
          </div>
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Niegolewskich;
